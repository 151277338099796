export const environment = {
  production: false,
  urlApi: 'https://o5x3y1p9y9.execute-api.us-east-1.amazonaws.com/dev/api/v1',
  // urlApiExpress: 'http://45.169.237.26:3000/api/v1',
  urlApiExpress: 'https://api.porvenirsofipo.mx:3000/api/v1',  
  firebaseConfig: {
    projectId: "sofipo-d9374",
    appId: "1:387487176425:web:83c63abf0d7e9d6546c278",
    storageBucket: "sofipo-d9374.appspot.com",
    apiKey: "AIzaSyCnKns7tvqlKEBYvXDpAAbGrFhHOloATfU",
    authDomain: "sofipo-d9374.firebaseapp.com",
    messagingSenderId: "387487176425",
    measurementId: "G-MYYLG8HJ6Y"
  },
  variables: {
    razonSocial: "SFP Porvenir, S.A. de C.V., S.F.P.",
    sector: "Sociedades Financieras Populares",
  }
};
